import React, { FC } from "react"
import Page from "../components/page"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

const TrackingPrivacyPolicyPage: FC = () => {
  const data = useStaticQuery(graphql`
    query {
      matomoBadgeImg: file(relativePath: { eq: "matomo-privacy-badge.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const MatomoBadge = () => {
    const ImgContainer = styled.div`
      max-width: 200px;
      margin: 0 auto;
    `
    return (
      <ImgContainer>
        <Img fluid={data.matomoBadgeImg.childImageSharp.fluid} alt="" />
      </ImgContainer>
    )
  }

  return (
    <Page>
      <h1>Tracking Privacy Policy Amendment</h1>

      <MatomoBadge />

      <h3>1 – About Matomo</h3>
      <p>
        <a href="https://matomo.org">Matomo</a> is an{" "}
        <a href="https://en.wikipedia.org/wiki/Open-source_software">
          open source
        </a>{" "}
        web analytics platform. A web analytics platform is used by a website
        owner in order to measure, collect, analyse and report visitors data for
        purposes of understanding and optimizing their website. If you would
        like to see what Matomo looks like, you can access a demo version at:{" "}
        <a
          href="https://demo.matomo.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://demo.matomo.org
        </a>
        .
      </p>

      <h3>2 – Purpose of the processing</h3>
      <p>
        Matomo is used to analyse the behaviour of the website visitors to
        identify potential pitfalls; not found pages, search engine indexing
        issues, which contents are the most appreciated… Once the data is
        processed (number of visitors reaching a not found pages, viewing only
        one page…), Matomo is generating reports for website owners to take
        action, for example changing the layout of the pages or publishing some
        fresh content.
      </p>
      <p>Matomo is processing the following personal data:</p>
      <ul>
        {/*<li>Cookies</li>*/}
        <li>IP address</li>
        {/*<li>User ID</li>*/}
        <li>Custom Dimensions</li>
        <li>Custom Variables</li>
        {/*<li>Order ID</li>*/}
        <li>Location of the user</li>
      </ul>
      <p>And also:</p>
      <ul>
        <li>Date and time</li>
        <li>Title of the page being viewed</li>
        <li>URL of the page being viewed</li>
        <li>URL of the page that was viewed prior to the current page</li>
        <li>Screen resolution</li>
        <li>Time in local timezone</li>
        <li>Files that were clicked and downloaded</li>
        <li>Link clicks to an outside domain</li>
        <li>Pages generation time</li>
        <li>Country, region, city</li>
        <li>Main Language of the browser</li>
        <li>User Agent of the browser</li>
        <li>
          Session recording, mouse events (movements, content forms and clicks)
        </li>
        <li>Form interactions</li>
        <li>Media interactions</li>
        <li>A/B Tests</li>
      </ul>
      <p>
        The processing of personal data with Matomo is based on legitimate
        interests.
      </p>

      <h3>3 – The legitimate interests</h3>
      <p>
        Processing your personal data such as clicks is helping us identify what
        is working and what is not on our website. For example, it helps us
        identify if the way we are communicating is engaging or not and how we
        can organize the structure of the website better. Our team is benefiting
        from the processing of your personal data, and they are directly acting
        on the website. By processing your personal data, you can profit from a
        website which is getting better and better.
      </p>
      <p>
        Without the data, we would not be able to provide you the service we are
        currently offering to you. Your data will be used only to improve the
        user experience on our website and help you find the information you are
        looking for.
      </p>

      <h3>4 – Recipient of the personal data</h3>
      <p>The personal data received through Matomo are sent to:</p>
      <p>
        Our trusted analytics web hosting provider offering the Matomo Analytics
        Cloud by InnoCraft. InnoCraft, 150 Willis St, 6011 Wellington, New
        Zealand.
      </p>

      <h3>5 – Details of transfers to third country and safeguards</h3>
      <p>
        Matomo data is hosted in{" "}
        <b>
          <i>Germany.</i>
        </b>
      </p>

      <h3>
        6 – Retention period or criteria used to determine the retention period
      </h3>
      <p>
        We are keeping the personal data captured within Matomo for a period of{" "}
        <b>
          <i>6 months</i>
        </b>
        .
      </p>

      <h3>7 – The existence of each of the data subject’s rights</h3>
      <p>
        As Matomo is processing personal data on legitimate interests, you can
        exercise the following rights:
      </p>
      <ul>
        <li>
          Right of access: you can ask us at any time to access your personal
          data.
        </li>
        <li>
          Right to erasure: you can ask us at any time to delete all the
          personal data we are processing about you.
        </li>
        <li>
          Right to object: you can object to the tracking of your personal data
          by using the following opt-out feature:
        </li>
      </ul>
      <iframe
        title="Matomo Opt-Out"
        width="100%"
        frameBorder="0"
        src="https://less-comsysto-reply.matomo.cloud/index.php?module=CoreAdminHome&action=optOut&language=en&backgroundColor=e8e8e8&fontColor=&fontSize=&fontFamily=sans-serif"
      />

      <h3>8 – The right to lodge a complaint with a supervisory authority</h3>
      <p>
        If you think that the way we process your personal data with Matomo
        analytics is infringing the law, you have the right to lodge a complaint
        with a supervisory authority.
      </p>

      <h3>
        9 – Whether the provision of personal data is part of a statutory or
        contractual requirement; or obligation and possible consequences of
        failing to provide the personal data
      </h3>
      <p>
        If you wish us to not process any personal data with Matomo, you can
        opt-out from it at any time. There will be no consequences at all
        regarding the use of our website.
      </p>

      <h3>
        10 – The existence of automated decision-making, including profiling and
        information about how decisions are made, the significance and the
        consequences
      </h3>
      <p>Matomo is not doing any profiling.</p>
    </Page>
  )
}

export default TrackingPrivacyPolicyPage
